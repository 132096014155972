import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const UserInformationSlice = createSlice({
  name: 'userInformation',
  initialState: {
    value: {},
  },
  reducers: {
    setUserInformation: (state, action) => {
      if (action.payload?.accessCode !== undefined) {
        Cookies.set('access-code', action.payload?.accessCode, { expires: 400, path: '/' });
      }
      state.value = action.payload;
    },
    logoutUser: (state) => {
      Cookies.remove('access-code');
      state.value = {};
    },
  },
});

export const { setUserInformation, logoutUser } = UserInformationSlice.actions;
export default UserInformationSlice.reducer;
