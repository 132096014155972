import React, { useState, useRef, useEffect } from 'react';
import { Backdrop, Box, keyframes, Modal, styled, TextField, Typography } from '@mui/material';
import bgblur from '../Assets/bgblur.png';
import axios from 'axios';
import { setUserInformation } from '../../Redux/UserInformationSlice';
import { useDispatch } from 'react-redux';

const BackgroundBackdrop = styled(Backdrop)({
  zIndex: -1,
  backgroundImage: `url(${bgblur})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const shakeAnimation = keyframes`
  0% { transform: translate(-50%, -50%) translateX(0); }
  25% { transform: translate(-50%, -50%) translateX(-10px); }
  50% { transform: translate(-50%, -50%) translateX(10px); }
  75% { transform: translate(-50%, -50%) translateX(-10px); }
  100% { transform: translate(-50%, -50%) translateX(0); }
`;

const StyledModalBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isShaking' })(
  ({ theme, isShaking }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(isShaking && {
      animation: `${shakeAnimation} 0.5s`,
    }),
  })
);

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '3rem',
  padding: '0.3rem',
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: '1.25rem',
  },
}));

const HyphenTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  margin: '0 12px',
  display: 'flex',
  alignItems: 'center',
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
}));

const AccessModal = ({ open, handleClose }) => {
  const [accessCode, setAccessCode] = useState(Array(6).fill(''));
  const [errorMessage, setErrorMessage] = useState('');
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    if (open) {
      setAccessCode(Array(6).fill(''));
      setErrorMessage('');
      setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 0);
    }
  }, [open]);

  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value.toUpperCase();
    if (!/^[A-Z0-9]?$/.test(value)) return;

    const newAccessCode = [...accessCode];
    newAccessCode[index] = value;
    setAccessCode(newAccessCode);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (newAccessCode.every((char) => char !== '')) {
      handleSubmit(newAccessCode.join(''));
    }
  };

  const handleSubmit = (code) => {
    const formattedCode = `${code.slice(0, 2)}-${code.slice(2, 4)}-${code.slice(4, 6)}`;

    axios
      .post('/rotr/checkAccessCode', { accessCode: formattedCode })
      .then((res) => {
        if (res.data.length === 0) {
          setErrorMessage('Invalid invitation code. Please try again.');
          triggerShake();
          return;
        }
        axios.post('/rotr/updateLastLogin', { accessCode: formattedCode }).catch(console.error);
        dispatch(
          setUserInformation({ accessCode: formattedCode, userType: res.data[0].user_type })
        );
        handleClose();
      })
      .catch(() => {
        setErrorMessage('Network error occurred. Please try again.');
        triggerShake();
      });
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
      const newAccessCode = [...accessCode];
      if (accessCode[index] === '' && index > 0) {
        newAccessCode[index - 1] = '';
        setAccessCode(newAccessCode);
        inputRefs.current[index - 1].focus();
      } else {
        newAccessCode[index] = '';
        setAccessCode(newAccessCode);
      }
    } else if (event.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <>
      <BackgroundBackdrop open={open} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <StyledModalBox isShaking={isShaking}>
          <Typography id='modal-title' variant='h6' component='h2' align='center'>
            Enter Invitation Code
          </Typography>
          {errorMessage && <ErrorMessage id='error-message'>{errorMessage}</ErrorMessage>}
          <Box display='flex' justifyContent='center' alignItems='center' mt={2}>
            {Array.from({ length: 6 }).map((_, index) => (
              <React.Fragment key={index}>
                <StyledTextField
                  id={`access-code-input-${index}`}
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  variant='outlined'
                  value={accessCode[index] || ''}
                  onChange={(event) => handleInputChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                  margin='normal'
                  inputRef={(el) => (inputRefs.current[index] = el)}
                />
                {(index === 1 || index === 3) && <HyphenTypography>-</HyphenTypography>}
              </React.Fragment>
            ))}
          </Box>
        </StyledModalBox>
      </Modal>
    </>
  );
};

export default AccessModal;
